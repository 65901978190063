<template>
  <div class="container">
    <!-- 返回头部 -->
    <el-backtop></el-backtop>

    <!-- 头部区域 -->
    <Header :headerData={} />

    <div class="panel">
      <div class="info">
        <H2>Submission Requirements</H2>
        <div class="txt-red">
          <H4>Authors must read and acknowledge that they have completed and are compliant with the requirements:</H4>
        </div>
        <div class="sect-desc">
          <p>The submission file must be a Microsoft Word document format (Docx).</p>
          <p>The manuscript text is single-spaced; Page size 8.5" x 11" inches (Letter); 20 pages maximum, uses Times New Roman Size 10-point black font text with 1-inch margins on all sides.</p>
          <p>Four Levels of Headings can be used:</p>
          <p>Article Title: Font Bolded and Size 18</p>
          <p>Heading Level 1: Font Bolded and Size 14</p>
          <p>Heading Level 2:  Size 12</p>    
          <p>Heading Level 3: Font italicized and Size 12</p>
          <p>Heading Level 4: Text underlines and Size 10</p>
          <p>Article Title can have a maximum length of 100 characters, including spaces.</p>
          <p>Manuscript Abstract can have a maximum length of 500 words.</p>
          <p>All illustrations, figures, graphs, charts, and tables are placed within the text at the appropriate points with their respective title and legend, rather than at the end of the manuscript. They must be all centered appropriately.</p>
          <p>All illustrations, figures, graphs, and charts should be inserted as image files in the word document (Only PNG and JPEG are acceptable) and must be individually uploaded as "Image File" during Step 2 of the submission along with the manuscript and other supplemental files. The title must be placed under the figure/graphs/charts.</p>      
          <p>All illustrations, figures, graphs, and charts should be inserted as image files in the word document (Only PNG and JPEG are acceptable) and must be individually uploaded as "Image File" during Step 2 of the submission along with the manuscript and other supplemental files. The title must be placed under the figure/graphs/charts.</p>
          <p>All image files uploaded must be named appropriately such as Fig1.png, Fig2.png, etc. Figures can only be labeled as whole numbers such as Fig 1, Fig 2, Fig 3, and NOT Fig 1a, Fig 1b, and so on.All image files uploaded must be named appropriately such as Fig1.png, Fig2.png, etc. Figures can only be labeled as whole numbers such as Fig 1, Fig 2, Fig 3, and NOT Fig 1a, Fig 1b, and so on.</p>
          <p>Multi-panel figures must be a single image file. Each panel must be labeled such as (1a), (1b), (1c), etc in the image; this figure must be labeled as Figure 1 and all individual panels described appropriately in the legend.</p>
          <p>Tables must be created in the word document. Do not insert and label an image as a table in the word document. All text color must be black, and the heading rows/columns must be shaded with light gray colors. The title must be placed over the Table.</p>
          <p>Equations must be created in the word document using the Insert Equation tool, and cannot be an inserted image file.</p>
          <p>Make use of the Bulleted and Numbered lists very minimally.</p>
          <p>We recommend the authors use the APA style guide for references, but you can use a discipline-specific reference style guide. References must be included at the end of the manuscript. Please leave one line spacing between each new reference and start on a new line.</p>
          <p>Where available, URLs or DOIs links for the references MUST be provided.</p>
          <p>List of Authors: Main author’s name and email address and for each additional author, include your faculty/teacher/advisor name and email address.</p>
          <p>Keywords: Keywords give your paper additional visibility when readers are searching for similar topics. If your topic is “AI in the high school classroom setting” Your keywords should say “artificial intelligence, high school, classroom setting” Press Enter to separate each individual item in the text box.</p>
          <p>Supporting Agencies: Write the name of your University or College along with any other institution that had supported the authors in conducting their research/review. Press Enter to separate each individual item in the text box.</p>
          <p>References or Bibliography: Please copy and insert all the citations for the manuscript in references section</p> 
        </div>

        <div class="txt-red">
          <p>The submission has not been previously published, nor is it before another journal for consideration (or an explanation has been provided in Comments to the Editor). Despite the Plagiarism check that we do, if later there are any complaints of plagiarism with proof, the submission will immediately be removed.</p>
        </div>


      <!-- Part: 8 -->
      <el-row justify="center">
        <div class="contact-us">
          <h3>JOURNAL CONTACT LIST</h3>
          <p>High School Student Submissions: info@ysassociation.org</p>
          <p>Editor: Y. Martin Lo, Biointellipro, martin@ysassociation.org</p>
          <p>Editor: Dr. Tim, Biointellipro, tim@ysassociation.org</p>
          <p>Editor: Dr. Tom, Artificial intelligence, tom@ysassociation.org</p>
        </div>
      </el-row>

    </div>
 
    </div>
  
    <!-- 底部 -->
    <Footer />
  </div>
</template>

<script>
  import Header from '../components/Header.vue'
  import Footer from "../components/Footer.vue";
 
  export default {
    name: "Home",
    data() {
      return {

      };
    },    
    components: {
      Footer,
      Header,
      // SectTurnBook,
    },

    mounted() {
    },

    methods: {
    }
  
  }

</script>


<style lang="less" scoped>
.container {
  // margin-bottom: 40px;
  padding-bottom: 0;
}
.panel{
  max-width: 1024px;
  margin: 10px auto;
  padding-left: 10px;
  text-align: left ;
  line-height: 1.5 ;
  .main-title {      
    font-size: 42px;
    font-weight: 700;
    letter-spacing: 0px;
  }
  > p{
    font-size: 16px;
    font-weight: 200;
  }
}
.sect-desc{
  text-align: left;
  line-height: 1.25 ;
  padding: 5px 2px;
  font-size: 14px;
  color:#333;
  p::before{
    content: '•';
    margin-right: 5px;
  }
}

.txt-link{
  font-size: 14px;
}
.txt-red{
  color: red;
}

.main {
  color: #333;
  padding: 0 10%;
  // 中区域的左侧文字区
}

.sect-title {
  max-width: 580px;
  text-align: center;
  margin: 24px auto;
  font-size: 26px;
  font-weight: bold;
}

.contact-us {
  line-height: 1.3 ;
  font-size: 12px;
}

//显示时按钮样式
.el-button--primary { //需要更改的按钮类型
  background: #2836e0 !important;
  border-color: #2836e0 !important;
}
//移入时按钮样式
.el-button--primary:hover {
  background: #005eff !important;
  border-color: #005eff !important;
  color: #FFF !important;
}

@media (max-width: 550px) {
  .panel{
    margin: 50px 10px !important;
    h1{
      font-size: 36px !important;
    }
  }
}
</style>
